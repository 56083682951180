function polyfillFetch() {
  if (!window.fetch) {
    return import("whatwg-fetch"); // eslint-disable-line no-undef
  }
  return Promise.resolve();
}

function polyfillIntl() {
  if (!window.Intl) {
    return Promise.all([
      import("intl"), // eslint-disable-line no-undef
      import("intl/locale-data/jsonp/en.js") // eslint-disable-line no-undef
    ]);
  }
  return Promise.resolve();
}

export default function() {
  return Promise.all([
    polyfillFetch(),
    polyfillIntl()
  ]);
}
